import { theme } from 'static/styles/theme';

export type TServerPhase = 'staging' | 'dev' | 'local' | 'production';

export const getServerPhase = () => {
  let serverPhase = localStorage.getItem('serverPhase');
  if (!serverPhase) {
    const environment = getEnvironment();
    serverPhase = environment === 'qa' ? 'staging' : environment;
    localStorage.setItem('serverPhase', serverPhase);
  }
  return serverPhase;
};

export function setServerPhase(serverPhase: TServerPhase) {
  localStorage.setItem('serverPhase', serverPhase);
}

export function getEnvironment() {
  if (window.location.host.includes('staging')) {
    return 'staging';
  }

  if (window.location.host.includes('qa')) {
    return 'qa';
  }

  if (window.location.host.includes('dev')) {
    return 'dev';
  }

  if (window.location.port === '3000' || window.location.port === '3001') {
    return 'local';
  }

  return 'production';
}

export function getEnvLogoColor(environment: TServerPhase | 'qa') {
  switch (environment) {
    case 'staging':
      return theme.newColors.acssentOrangeSecondary;
    case 'qa':
      return theme.newColors.acssentBluePrimary;
    case 'dev':
      return theme.newColors.blue;
    case 'local':
      return theme.newColors.red;
    default:
      return theme.newColors.primary;
  }
}

export function checkIfStringValueExist(value: string): boolean {
  if (!value || value === 'undefined' || value === 'null') return false;
  return true;
}

export function getHexOpacity(opacity: number) {
  const alpha = Math.round(opacity * 255);
  const hex = (alpha + 0x10000).toString(16).substr(-2).toUpperCase();
  return { hex, opacity };
}
