import { takeLatest, all, select, take, put } from 'redux-saga/effects';
import {
  loginFailure,
  loginRequest,
  loginSuccess,
  setNeedCertification,
} from 'store/reducers/login';
import {
  logoutInAppFailure,
  logoutInAppRequest,
  logoutInAppSuccess,
  clickLogout,
} from 'store/reducers/logout';
import { login, logoutInApp } from 'api';
import { RootState } from 'store/reducers';
import { removeMyInfo } from 'store/reducers/myInfo';
import { registerShopbyRequest } from 'store/reducers/register';
import { failure } from 'store/sagas/failure';
import { createFetchAction } from 'store/sagas/createFetchAction';
import {
  getUserTrackingInfoRequest,
  resetUserTrackingInfo,
} from 'store/reducers/algoliaSearch';
import { ILogInResponse, IResponse } from '@types';
import { PayloadAction } from '@reduxjs/toolkit';
import { getCookie } from 'utils/cookie';
import { getSourcePath, removeSourcePath } from 'utils/sessionStorageUtils';
import sendGtmDataLayer from 'utils/sendGtmDataLayer';
import setReservationInfoState from 'utils/setReservationInfoState';
import { customHistory } from 'App';
import { TFeeds } from 'types/feed';
import { resetRegisterTimestamp } from 'store/reducers/feed';
import { getServerPhase, setServerPhase, TServerPhase } from 'utils/common';

const queryString = require('query-string');

interface LocationState {
  isAuthorized?: boolean;
  from: { pathname: string };
}

function* loginSaga() {
  yield takeLatest(
    loginRequest.type,
    createFetchAction(
      login,
      loginSuccess,
      loginFailure,
      function* success(res: ILogInResponse) {
        const { jwt, user, isHibernating, isCertified } = yield res;

        const { email, nickname, thumbnail, brief, role, marketingAgreement } =
          user;

        if (user.role.name !== 'Authenticated') {
          alert('캠지기센터 아이디는 사용하실 수 없습니다');
          return;
        }

        if (isHibernating) {
          sessionStorage.setItem('tempJwt', jwt);
          customHistory.replace('/dormant');
          return;
        }

        // NOTE: 로그인 성공 시점에 샵바이 토큰이 없다면, 샵바이 회원가입
        const shopbyAccessToken: string | null | undefined =
          localStorage.getItem('shopbyAccessToken');

        if (!shopbyAccessToken) {
          yield put(registerShopbyRequest());
        }

        localStorage.setItem('jwt', jwt);
        localStorage.setItem('email', email);
        localStorage.setItem('nickname', nickname);
        brief && localStorage.setItem('brief', brief);

        yield put(getUserTrackingInfoRequest());

        const userId = user.id;

        if (userId) {
          localStorage.setItem('userToken', userId);
        }

        if (thumbnail && thumbnail.url) {
          localStorage.setItem('thumbnail', thumbnail.url);
        }

        if (typeof marketingAgreement === 'boolean') {
          localStorage.setItem(
            'marketingAgreement',
            String(marketingAgreement),
          );
        }

        const feedData: {
          name: string;
          data: TFeeds[];
          registerTimestamp: number;
        }[] = yield select((state: RootState) => state.feedReducer.feedData);

        const feedName: string = yield select(
          (state: RootState) => state.feedReducer.feedName,
        );

        if (feedName && feedData && feedData.length > 0) {
          yield put(resetRegisterTimestamp());
        }

        const locationState: LocationState = yield customHistory.location.state;

        if (!isCertified) {
          yield put(setNeedCertification(true));

          yield customHistory.push({
            pathname: '/mypage/info/edit',
            state: {
              from: { pathname: locationState?.from?.pathname },
              isAuthorized: locationState?.isAuthorized,
            },
          });

          return;
        }

        const isGoingToReservation = JSON.parse(
          sessionStorage.getItem('isGoingToReservation') || 'null',
        );

        const sourcePath = getSourcePath();
        const query = queryString.parse(customHistory.location.search);
        const { id: bookingId } = query;

        if (isGoingToReservation) {
          yield setReservationInfoState();
          sessionStorage.removeItem('isGoingToReservation');
          // 로그인 하지 않은 상태로 예약 하는 경우
          customHistory.push('/reservation');
        } else if (bookingId) {
          // 로그인 하지 않은 상태에서 예약페이지 접근시 쿼리로 bookignId 전달
          customHistory.push(`/reservation/result/${bookingId}`);
        } else if (locationState?.isAuthorized !== undefined) {
          // 로그인 하지 않은 상태로 예약 하는 경우
          customHistory.push('/reservation');
        } else if (locationState?.from.pathname) {
          // 로그인 하지 않은 상태로 <PrivateRoute/>로 점근하는 경우
          customHistory.push(locationState?.from.pathname);
        } else if (sourcePath) {
          // sourcePath 설정 되어 있는 경우
          yield removeSourcePath();
          yield customHistory.push(sourcePath);
        } else {
          customHistory.push('/');
        }
      },

      function* fail(error: Error | IResponse) {
        if (!localStorage.jwt) {
          if (error && error.message && typeof error.message === 'string') {
            yield alert(`${error.message}`);
          } else {
            yield alert('로그인 정보가 올바르지 않습니다.');
          }
          return;
        }
        yield failure(error);
      },
    ),
  );
}

/*
 * NOTE. 2024.05.20 이양우
 * 마이 - 로그아웃 모달의 로그아웃 버튼이 <Link/> 로 되어있어
 * 앱에서 로그아웃이 정상적으로 되지 않는 이슈 발생
 * <Link/> 대신 saga 에서 로직 처리 이후 홈으로 이동하도록 수정
 */

function* logoutInAppSaga() {
  yield takeLatest(
    logoutInAppRequest.type,
    createFetchAction(
      logoutInApp,
      logoutInAppSuccess,
      logoutInAppFailure,
      function* success() {
        const serverPhase = getServerPhase();
        yield sessionStorage.clear();
        yield localStorage.clear();
        yield put(removeMyInfo());
        yield put(resetUserTrackingInfo());
        yield sendGtmDataLayer({ user_id: undefined, crm_id: undefined });
        if (serverPhase !== 'production')
          yield setServerPhase((serverPhase as TServerPhase) || 'dev');
        yield window.location.replace('/');
      },
      failure,
    ),
  );
}

function* logoutSaga() {
  while (true) {
    const action: PayloadAction<boolean> = yield take(clickLogout.type);

    if (window.BootpayRNWebView) {
      window.BootpayRNWebView.postMessage('LOGOUT');
    }

    const serverPhase = getServerPhase();
    if (action.payload) {
      const deviceId = getCookie('deviceId')();

      if (deviceId) {
        yield put(logoutInAppRequest(deviceId));
      } else {
        yield sessionStorage.clear();
        yield localStorage.clear();
        yield put(removeMyInfo());
        yield put(resetUserTrackingInfo());
        yield sendGtmDataLayer({ user_id: undefined, crm_id: undefined });
        yield window.location.replace('/');
      }
      if (serverPhase !== 'production')
        yield setServerPhase((serverPhase as TServerPhase) || 'dev');
    } else {
      yield sessionStorage.clear();
      yield localStorage.clear();
      if (serverPhase !== 'production')
        yield setServerPhase((serverPhase as TServerPhase) || 'dev');
      yield put(removeMyInfo());
      yield put(resetUserTrackingInfo());
      yield sendGtmDataLayer({ user_id: undefined, crm_id: undefined });
      yield window.location.replace('/');
    }
  }
}

export default function* loginoutSaga() {
  yield all([loginSaga(), logoutInAppSaga(), logoutSaga()]);
}
